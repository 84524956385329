html {
    scroll-behavior: smooth;
  }

.bg-primary  {
    background-color: #679a2a !important;
}

.headroom--not-top.navbar-theme-primary {
    background-color: #679a2a !important;
}

.btn-primary {
    background-color: #679a2a !important;
}

.btn-secondary {
    color: #fff;
    background-color: #883f3f;
    border-color: #883f3f;
    box-shadow: 0 0.125rem 0.25rem rgba(13,20,49,.07);
}

/* @media (max-width: 1200px){
    .main-top-padding {
        padding-top: 2rem;
    }
} */

/*** Navbar ***/

.navbar-light .navbar-brand {
    color: #506690;
}
.navbar-light .navbar-brand {
    color: rgba(80,102,144,.8);
}
.navbar-brand {
    font-size: .875rem;
    font-weight: 600;
    text-transform: uppercase;
}
.navbar-brand {
    display: inline-block;
    padding-top: 0.0625rem;
    padding-bottom: 0.0625rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}
.text-action, a {
    color: #0648b3;
    transition: all .2s ease;
}
a {
    color: #0648b3;
    text-decoration: none;
    background-color: transparent;
}

@media (min-width: 992px){
    .navbar-expand-lg .navbar-collapse {
        display: flex!important;
        flex-basis: auto;
    }
}

.collapse:not(.show) {
    display: none;
}
.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

.navbar-collapse-header {
    display: none;
}

@media (min-width: 992px){
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }
}

.justify-content-center {
    justify-content: center!important;
}
.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
dl, ol, ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

.p, ol li, p, ul li {
    font-family: "Nunito Sans",sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
}

.navbar .navbar-nav .nav-link {
    font-size: 1rem;
    text-transform: normal;
    letter-spacing: 0;
    font-weight: 400;
    transition: all .8s cubic-bezier(.34,1.45,.7,1);
}
.navbar-light .navbar-nav .nav-link {
    color: #66799e;
}
@media (min-width: 992px){
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}
@media (min-width: 992px){
    .navbar-nav .nav-link {
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-radius: 0.5rem;
    }
}
.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}
.nav-link {
    color: #4a5073;
}
.nav-link {
    display: block;
    padding: 0.25rem 0.75rem;
}
.text-action, a {
    color: #0648b3;
    transition: all .2s ease;
}
a {
    color: #0648b3;
    text-decoration: none;
    background-color: transparent;
}



@media (min-width: 992px){
    .navbar-expand-lg>.container, .navbar-expand-lg>.container-fluid {
        flex-wrap: nowrap;
    }
}

.navbar>.container, .navbar>.container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}




@media (min-width: 576px){
    .section-header {
        position: relative;
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
}

@media (min-width: 992px){
    .section-header {
        position: relative;
        padding-top: 10rem;
        padding-bottom: 10rem;
    }
}

.section-header {
    position: relative;
    padding-top: 2rem;
    padding-bottom: 3rem;
}

#testimonials {
    position: relative;
    padding-top: 10rem;
    padding-bottom: 10rem;
}

/* .section-header {
    position: relative;
    padding-top: 10rem;
    padding-bottom: 10rem;
} */



.pb-7, .py-7 {
    padding-bottom: 8rem!important;
}
@media (min-width: 992px){
.pb-lg-11, .py-lg-11 {
    padding-bottom: 16rem!important;
}
}

.bg-soft {
    background-color: #f4f6fc!important;
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
}
*, ::after, ::before {
    box-sizing: border-box;
}



body {
    margin: 0;
    font-family: "Nunito Sans",sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    color: #4a5073;
    text-align: left;
    background-color: #fff;
  }
  
  .navbar-main {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
  }
  .headroom--pinned {
    transform: translateY(0);
  }
  .headroom {
    will-change: transform;
    background-color: inherit;
    transition: all .2s ease;
  }
  @media (min-width: 992px){
    .pl-lg-6, .px-lg-6 {
      padding-left: 5rem!important;
    }
  }
  
  @media (min-width: 992px){
    .pr-lg-6, .px-lg-6 {
      padding-right: 5rem!important;
    }
  }
  
  @media (min-width: 992px){
    .pb-lg-3, .py-lg-3 {
      padding-bottom: 1rem!important;
    }
  }
  
  @media (min-width: 992px){
    .pt-lg-3, .py-lg-3 {
      padding-top: 1rem!important;
    }
    .headroom--pinned, .headroom--unpinned, .position-fixed {
      position: fixed!important;
    }
  }
  
  @media (min-width: 992px){
    .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
   }
  }
  
  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
  }
  article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }
  *, ::after, ::before {
    box-sizing: border-box;
  }
  
  nav {
    display: block;
  }
  
  .pattern.top {
    top: -10px;
  }

  .pattern.bottom {
    bottom: -10px;
    transform: rotate(180deg);
  }
  
  .pattern {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-image: url(./../img/patterns/wave.svg);
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: 1450px 160px;
    height: 0;
    padding: 0;
    padding-bottom: 140px;
  }

  @media (max-width: 991.98px){
      .pattern {
          background-size: 700px 203px;
      }
  }

  .pattern-soft {
    background-image: url(./../img//patterns//wave-soft.svg);
 }

.display-2 {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1.3;
}

@media (max-width: 991.98px){
    .display-2 {
        font-size: 1.875rem;
    }
    
}

@media (max-width: 1200px){
    .display-2 {
        font-size: calc(1.475rem + 2.7vw);
    }
}

  .mb-3, .my-3 {
    margin-bottom: 1rem!important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: "Nunito Sans",sans-serif;
    font-weight: 600;
}

.h2, h2 {
    font-size: 2rem;
}

@media (max-width: 1200px){
    .h2, h2 {
        font-size: calc(1.325rem + .9vw);
    }
}

@media (min-width: 1200px){
    .header-space {
        margin-top: 3rem!important;
    }
}



.h1, h1 {
    font-size: 2.5rem;
}

@media (max-width: 1200px){
    .h1, h1 {
        font-size: calc(1.375rem + 1.5vw);
    }
}


.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.3;
    color: #24262b;
}
h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}
*, ::after, ::before {
    box-sizing: border-box;
}

/** section two **/
@media (min-width: 992px){
    .section-lg {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
}

@media (min-width: 992px){
    .section {
        position: relative;
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
}
@media (min-width: 576px){
    .section-lg {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
}
@media (min-width: 576px){
    .section {
        position: relative;
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}
.section {
    position: relative;
    padding-top: 3rem;
    padding-bottom: 3rem;
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
}
*, ::after, ::before {
    box-sizing: border-box;
}


  
.font-weight-bolder {
    font-weight: 700!important;
}

@media (min-width: 768px){
    .mb-md-7, .my-md-7 {
        margin-bottom: 8rem!important;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.mb-5, .my-5 {
    margin-bottom: 3rem!important;
}

@media (min-width: 992px){
    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
}
/* @media (min-width: 768px){
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
} */
/* .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
} */

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
*, ::after, ::before {
    box-sizing: border-box;
}

.card {
    position: relative;
}

.shadow-soft {
    box-shadow: 0 .75rem 1.5rem rgba(18,38,63,.03)!important;
}
[class*=shadow] {
    transition: all .2s ease;
}
.border-light {
    border-color: #eaedf2!important;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0.0625rem solid rgba(243,247,250,.05);
    border-radius: 0.5rem;
}
*, ::after, ::before {
    box-sizing: border-box;
}

.card .card-header {
    background: 0 0;
}

.card-header:first-child {
    border-radius: calc(0.5rem - 0.0625rem) calc(0.5rem - 0.0625rem) 0 0;
}
.p-0 {
    padding: 0!important;
}
.card-header {
    padding: 1.25rem 1.5rem;
    margin-bottom: 0;
    background-color: #f3f7fa;
    border-bottom: 0.0625rem solid rgba(243,247,250,.05);
}

.card-body {
    flex: 1 1 auto;
    padding: 1.5rem;
}

/* [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
.text-primary, .text-primary:hover {
    color: #0648b3!important;
}
.text-primary {
    color: #0648b3!important;
}
.mr-2, .mx-2 {
    margin-right: 0.5rem!important;
}
[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
}
.fa, .fas {
    font-weight: 900;
}
.fa, .far, .fas {
    font-family: "Font Awesome 5 Free";
}
.fa, .fab, .fad, .fal, .far, .fas {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
} */

.mb-4, .my-4 {
    margin-bottom: 1.5rem!important;
}

.justify-content-center {
    justify-content: center!important;
}
.d-flex {
    display: flex!important;
}
.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}
dl, ol, ul {
    margin-top: 0;
    margin-bottom: 1rem;
}
*, ::after, ::before {
    box-sizing: border-box;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.list-group-item:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.list-group-item {
    border: 0;
}
.pb-1, .py-1 {
    padding-bottom: 0.25rem!important;
}
.pl-0, .px-0 {
    padding-left: 0!important;
}
.d-flex {
    display: flex!important;
}
.list-group-item {
    position: relative;
    display: block;
    padding: 1rem 1rem;
    margin-bottom: -0.0625rem;
    background-color: #fff;
    border: 0.0625rem solid #f4f6fc;
}
.p, ol li, p, ul li {
    font-family: "Nunito Sans",sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
}
*, ::after, ::before {
    box-sizing: border-box;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
}

ul {
    list-style-type: disc;
}

.list-group-item {
    border: 0;
}

/******  Section Thress, Our Clients *******/
.text-white, .text-white:hover, .text-white a:not(.btn),  .text-white h3, .text-white h6 {
    color: #fff!important;
}



.text-gray, .text-gray:hover {
    color: #4a5073!important;
}
.text-gray {
    color: #4a5073!important;
}

.font-weight-normal {
    font-weight: 400!important;
}



/* .display-2 {
    color: #fff;
} */

.pl-5, .px-5 {
    padding-left: 3rem!important;
}

.pr-5, .px-5 {
    padding-right: 3rem!important;
}
.lead {
    font-size: 1.25rem;
    font-weight: 400;
}

.p, ol li, p, ul li {
    font-family: "Nunito Sans",sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}


img {
    max-width: 100%;
}
img {
    vertical-align: middle;
    border-style: none;
}
*, ::after, ::before {
    box-sizing: border-box;
}

.rounded-top {
     border-top-left-radius: 0.5rem!important; 
     border-top-right-radius: 0.5rem!important; 
}
.card-img-top {
    width: 100%;
    border-top-left-radius: calc(0.5rem - 0.0625rem);
    border-top-right-radius: calc(0.5rem - 0.0625rem);
}
img {
    overflow-clip-margin: content-box;
    overflow: clip;
}

/*** Footer **/

.footer {
    display: flex;
    position: relative;
}

.pb-3, .py-3 {
    padding-bottom: 1rem!important;
}

.pt-6, .py-6 {
    padding-top: 5rem!important;
}

@media (min-width: 768px){
    .pt-md-8, .py-md-8 {
        padding-top: 10rem!important;
    }
}

@media (min-width: 992px){
    .pt-lg-10, .py-lg-10 {
        padding-top: 14rem!important;
    }
}

.footer ul {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
}

dl, ol, ul {
    margin-top: 0;
    margin-bottom: 1rem;
}


.overflow-hidden {
    overflow: hidden!important;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
} 

@media (min-width: 576px){
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px){
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px){
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px){
    .container {
        max-width: 1140px;
    }
}


/* @media (min-width: 992px){
    .mb-lg-0, .my-lg-0 {
        margin-bottom: 0!important;
    }
}

.mb-4, .my-4 {
    margin-bottom: 1.5rem!important;
}
@media (min-width: 992px){
    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
} */


.text-center {
    text-align: center!important;
}
.align-items-center {
    align-items: center!important;
}
.justify-content-center {
    justify-content: center!important;
}
.d-flex {
    display: flex!important;
}


/******** Pricing  ***********/
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn-a-brc-tp:not(.disabled):not(:disabled).active, .btn-brc-tp, .btn-brc-tp:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle), .btn-h-brc-tp:hover, .btn.btn-f-brc-tp:focus, .btn.btn-h-brc-tp:hover {
    border-color: transparent;
}
.btn-outline-blue {
    color: #0d6ce1;
    border-color: #5a9beb;
    background-color: transparent;
}
.btn {
    cursor: pointer;
    position: relative;
    z-index: auto;
    border-radius: .175rem;
    transition: color .15s,background-color .15s,border-color .15s,box-shadow .15s,opacity .15s;
}
.border-2 {
    border-width: 2px!important;
    border-style: solid!important;
    border-color: transparent;
}
.bgc-white {
    background-color: #fff!important;
}


.text-green-d1 {
    color: #277b5d!important;
}
.letter-spacing {
    letter-spacing: .5px!important;
}
.font-bolder, .text-600 {
    font-weight: 600!important;
}
.text-170 {
    font-size: 1.7em!important;
}

.text-purple-d1 {
    color: #6d62b5!important;
}

.text-primary-d1 {
    color: #276ab4!important;
}
.text-secondary-d1 {
    color: #5f718b!important;
}
.text-180 {
    font-size: 1.8em!important;
}
.text-150 {
    font-size: 1.5em!important;
}
.text-danger-m3 {
    color: #e05858!important;
}
.rotate-45 {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.position-l {
    left: 0;
}
.position-b, .position-bc, .position-bl, .position-br, .position-center, .position-l, .position-lc, .position-r, .position-rc, .position-t, .position-tc, .position-tl, .position-tr {
    position: absolute!important;
    display: block;
}
.mt-n475, .my-n475 {
    margin-top: -2.5rem!important;
}
.ml-35, .mx-35 {
    margin-left: 1.25rem!important;
}

.text-dark-l1 {
    color: #56585e!important;
}
.text-90 {
    font-size: .9em!important;
}
.text-left {
    text-align: left!important;
}

.mt-25, .my-25 {
    margin-top: .75rem!important;
}

.text-110 {
    font-size: 1.1em!important;
}

.deleted-text{
text-decoration:line-through;;    
}
